<script setup>
import { computed, toRefs, ref } from 'vue'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  colValue: { type: Number, required: false, default: 1 },
  placeholder: { type: String, required: false, default: 'Search' },
  aditionalInputClass: { type: String, required: false, default: '' },
  aditionalContainerClass: { type: String, required: false, default: '' },
  aditionalMainClass: { type: String, required: false, default: '' }
})
const emit = defineEmits(['search'])
const { placeholder, aditionalMainClass } = toRefs(props)
const stringClassByRules = computed(() => {
  return `col-${props.colValue || 12} ` + props.aditionalContainerClass
})

const userStore = useUserStore()
const localSearchValue = ref(userStore.searchValue)

function clearSearch() {
  localSearchValue.value = ''
  userStore.updateSearch('')
  emit('search', '')
}
</script>

<template>
  <div class="d-flex" :class="aditionalMainClass">
    <div :class="stringClassByRules" class="input-search">
      <slot name="beforeInput"></slot>
      <div class="position-relative">
        <input
          :class="'input-search form-control border-end-0 border ' + aditionalInputClass"
          type="search"
          v-model="localSearchValue"
          v-debounce.2000="
            (e) => {
              userStore.updateSearch(e.target.value)
              emit('search', e.target.value)
            }
          "
          @keydown.enter="
            (e) => {
              userStore.updateSearch(e.target.value)
              emit('search', e.target.value)
            }
          "
          :placeholder="placeholder"
          id="example-search-input"
        />
        <button
          class="clear-icon-search-store"
          type="button"
          @click="clearSearch"
          v-if="localSearchValue"
        >
          <font-awesome-icon icon="fa-solid fa-times" />
        </button>
      </div>
    </div>
    <slot name="extraElements"></slot>
  </div>
</template>
