// src/helpers/ecommerceTracker.js
import { logEventSafe } from '@/helpers/analyticsHelper'
import { useShoppingStore } from '@/stores/shopping'

export const trackViewCart = () => {
  const store = useShoppingStore()

  logEventSafe('view_cart', {
    currency: 'MXN',
    value: store.cartTotalPrice,
    items: store.cartContent.map((p) => ({
      item_id: p.item.id,
      item_name: p.item.title,
      quantity: p.amount,
      price: p.item.price_discount || p.item.price
    }))
  })
}

export const trackBeginCheckout = () => {
  const store = useShoppingStore()

  logEventSafe('begin_checkout', {
    currency: 'MXN',
    value: store.cartTotalPrice,
    items: store.cartContent.map((p) => ({
      item_id: p.item.code,
      item_name: p.item.title,
      quantity: p.amount,
      price: p.item.price_discount || p.item.price
    }))
  })
}

export const trackPurchase = (orderId, coupon = null) => {
  const store = useShoppingStore()

  logEventSafe('purchase', {
    transaction_id: orderId,
    affiliation: 'Online Store',
    value: store.cartTotalPrice,
    currency: 'MXN',
    coupon: coupon || undefined,
    items: store.cartContent.map((p) => ({
      item_id: p.item.id,
      item_name: p.item.title,
      quantity: p.amount,
      price: p.item.price_discount || p.item.price
    }))
  })
}

export const trackAddToCart = (item, amount) => {
  logEventSafe('add_to_cart', {
    currency: 'MXN',
    value: (item.price_discount || item.price) * amount,
    items: [
      {
        item_id: item.id,
        item_name: item.title,
        quantity: amount,
        price: item.price_discount || item.price
      }
    ]
  })
}

export const trackViewItem = (item) => {
  logEventSafe('view_item', {
    items: [
      {
        item_id: item.id,
        item_name: item.title,
        price: item.price_discount || item.price
      }
    ]
  })
}
