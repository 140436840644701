import { defineStore } from 'pinia'
import { ref } from 'vue'

const images = import.meta.globEager('@/assets/images/**/*.{png,jpg,jpeg,svg,webp,gif,ico}')

function findImageKey(obj, searchString) {
  return Object.keys(obj).find((key) => key.includes(searchString))
}

export const useConfigStore = defineStore('config', {
  state: () => ({
    sidebarVisible: ref(false),
    adminSidebarVisible: ref(false),
    clientMinimunPurchasePrice: ref(0),
    siteConfig: ref({
      promotionFirstBuy: {},
      titleBase: '',
      brandName: '',
      displayWholesalePrices: false,
      minimumPurchasePrice: 0,
      shoppingCartViewConfig: {},
      sideCartMenuConfig: {
        title: '',
        labelEmptyCart: '',
        messageMinimumPurchasePriceAcomplished: '',
        messageEmptyCart: '',
        messageEmptyCartBtn: '',
        messageMinimunPurchasePrice: '',
        progressBarRequired: false,
        logicCreditBalanceByUser: false,
        progressBarRequiredCredit: false,
        messageCreditBalanceByUserPart1: '',
        messageCreditBalanceByUserPart2: '',
        messageCreditBalanceByUserAvailable: '',
        footerValues: []
      },
      colors: {
        '--color-primary': '',
        '--color-secondary': '',
        '--color-tertiary': '',
        '--color-tertiary-alternative': '',
        '--color-success-alternative': '',
        '--color-secondary-alternative': '',
        '--color-footer-main': '',
        '--color-footer-main-title': '',
        '--color-footer-accordion-item': '',
        '--color-footer-background': '',
        '--color-footer-secondary-background': '',
        '--color-footer-secondary-title': '',
        '--color-text-app': '',
        '--color-text-app-alternative': ''
      },
      assetsFolderPrefix: ''
    })
  }),
  getters: {
    getDisplayWholesalePrices() {
      return this.siteConfig.displayWholesalePrices
    },
    getStatePromotionFirstBuy() {
      if (!this.siteConfig?.promotionFirstBuy?.date_expire) return false // Evitar errores si no hay fecha

      const dateInit = new Date(this.siteConfig.promotionFirstBuy.date_init)

      // Convertir la fecha de expiración a un formato sin problemas de zona horaria
      const expireDate = new Date(this.siteConfig.promotionFirstBuy.date_expire + 'T23:59:59')

      // Obtener la fecha actual sin horas, minutos ni segundos
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)

      // Verificar si la fecha de inicio es menor a la fecha actual y la fecha de expiración es mayor a la fecha actual
      return dateInit <= currentDate && expireDate >= currentDate
    },
    getPromotionFirstBuyConfig() {
      return this.siteConfig?.promotionFirstBuy
    },
    getRequiredProgressBarCredit() {
      return (
        this.siteConfig?.sideCartMenuConfig?.logicCreditBalanceByUser &&
        this.siteConfig?.sideCartMenuConfig?.progressBarRequiredCredit
      )
    },
    getIsLogicCreditBalanceByUserActive() {
      return this.siteConfig?.sideCartMenuConfig?.logicCreditBalanceByUser || false
    },
    onlyQuotations() {
      return this.siteConfig?.onlyQuotations || false
    },
    getPrimaryColorByBrand() {
      return this.siteConfig.colors['--color-primary']
    },
    getMinPurchasePriceMessage() {
      const messageBase = this.siteConfig?.shoppingCartViewConfig?.minPurchasePriceMessage
      const minPrice =
        this.clientMinimunPurchasePrice || this.siteConfig?.minimumPurchasePrice || 1500

      const createMessageWithMinValue = messageBase.replace('$', `$${minPrice}`)
      return createMessageWithMinValue
    },
    getMinimumPurchaseMessageAcomplished() {
      const messageBase =
        this.siteConfig?.sideCartMenuConfig?.messageMinimumPurchasePriceAcomplished
      const minPrice =
        this.clientMinimunPurchasePrice || this.siteConfig?.minimumPurchasePrice || 1500

      const createMessageWithMinValue = messageBase.replace('$', `$${minPrice}`)
      return createMessageWithMinValue
    },
    getShoppingCartViewConfig() {
      return this.siteConfig?.shoppingCartViewConfig
    },
    getSideCartMenuConfig() {
      return this.siteConfig?.sideCartMenuConfig
    },
    getMinimumPurchasePriceByBrand() {
      return this.clientMinimunPurchasePrice || this.siteConfig?.minimumPurchasePrice || 1500
    },
    getBaseTitleByBrand() {
      return this.siteConfig?.titleBase || 'Store'
    },
    getBrandName() {
      return this.siteConfig.brandName
    },
    getPrimaryColor() {
      return this.siteConfig.colors['--color-primary']
    },
    getSiteConfig() {
      return this.siteConfig
    },
    getSiteConfigColors() {
      return this.siteConfig.colors
    },
    getAssetsFolderImages() {
      return `/images/${this.siteConfig.assetsFolderPrefix}`
    },
    getSidebarVisibleAdmin() {
      return this.adminSidebarVisible
    },
    getsidebarVisible() {
      return this.sidebarVisible
    }
  },
  actions: {
    updateMinimunPurchasePrice(value) {
      if (!value) return
      this.clientMinimunPurchasePrice = value
      this.siteConfig.minimumPurchasePrice = this.clientMinimunPurchasePrice || 1500
    },
    async findLottieFile(fileName) {
      try {
        const lottieModule = await import(`@/assets/lotties/${this.getBrandName}/${fileName}.json`)
        return lottieModule.default
      } catch (error) {
        console.error('Error loading Lottie animation:', error)
        // Fallback to a default animation if the specific brand animation is not found
        const defaultLottieModule = await import('@/assets/lotties/default.json')
        return defaultLottieModule.default
      }
    },
    async findLogoImage(fileName) {
      const imagePath = `${this.getAssetsFolderImages}/${fileName}`
      const matchedKey = findImageKey(images, imagePath)

      if (matchedKey) {
        return images[matchedKey].default // La ruta de la imagen será resuelta aquí
      } else {
        console.error(`Imagen no encontrada: ${imagePath}`)
        return null
      }
    },
    setConfig(config) {
      this.siteConfig = config
    },
    updateAdminSidebarVisibility(value) {
      this.adminSidebarVisible = value
    },
    toogleAdminSidebar() {
      this.adminSidebarVisible = !this.adminSidebarVisible
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible
    }
  },
  persist: true
})
