import { logEvent as firebaseLogEvent, setUserId, setUserProperties } from 'firebase/analytics'

import { analytics } from '@/../firebaseConfig'
import { useUserStore } from '@/stores/user'

const environment = import.meta.env.VITE_ENVIRONMENT

// Cache local
let lastUserId = null
let lastUserProperties = null

export const logEventSafe = (eventName, params = {}, options = false) => {
  try {
    if (environment === 'production' && analytics) {
      let userId = null
      let userProperties = null

      if (options) {
        userId = options.userId
        userProperties = options.userProperties
      } else {
        const userStore = useUserStore()
        userId = userStore.userId
        userProperties = userStore.userProperties
      }

      // Solo setea si hay cambios
      if (userId && userId !== lastUserId) {
        setUserId(analytics, String(userId))
        lastUserId = userId
      }

      if (userProperties && JSON.stringify(userProperties) !== JSON.stringify(lastUserProperties)) {
        setUserProperties(analytics, userProperties)
        lastUserProperties = userProperties
      }

      firebaseLogEvent(analytics, eventName, params)
    } else {
      console.debug(`[Analytics Mock] Event: ${eventName}`, { params, options })
    }
  } catch (error) {
    console.error('Error logging event:', error)
  }
}
