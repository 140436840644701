<template>
  <div>
    <dl class="ms-2">
      <li
        v-for="(category, index) in categoriesToShow"
        class="p-0 my-3 mx-2 text-black cursor-pointer fw-light"
        :key="category?.id"
      >
        <div class="d-flex flex-row h-100 py-3 px-4 optionHover">
          <span @click="goToCategory(category?.id, category?.name)" class="col">{{
            category?.name
          }}</span>
          <span
            @click="toggleCategories(index)"
            class="col text-end"
            v-if="category?.sons?.length > 0"
          >
            <font-awesome-icon
              class="text-primary-app"
              :icon="['fa', iconByState(category?.active)]"
              size="lg"
            />
          </span>
        </div>
        <ShowCategories
          :categories="categories"
          :position="positionToShow(index)"
          v-if="!category?.active"
        />
      </li>
    </dl>
  </div>
</template>

<script setup>
import ShowCategories from '@/components/layout/navigation/ShowCategories.vue'
import { ref, toRefs, onMounted } from 'vue'
import { useConfigStore } from '@/stores/config'
import router from '@/router'
import { useAnalytics } from '@/composables/useAnalytics'

const { trackCategoryClick } = useAnalytics()
const configStore = useConfigStore()

const props = defineProps({
  position: { type: Array, default: () => [] },
  categories: { type: Array, required: true }
})

const categoriesToShow = ref([])
const { position, categories } = toRefs(props)

function positionToShow(index) {
  let response = JSON.parse(JSON.stringify(position.value))
  response.push(index)
  return response
}

function iconByState(state) {
  return !state ? 'angle-down' : 'angle-right'
}

function generateCategoriesToShow() {
  if (position.value.length === 0) {
    return categories.value
  }
  let response = []

  for (let i = 0; i < position.value.length; i++) {
    response = getCategoriesToShow(position.value[i], i ? response : categories.value)
  }
  return response
}

function getCategoriesToShow(position, response = []) {
  return response[position].sons
}

function goToCategory(id, name) {
  trackCategoryClick(name, id)
  if (window.innerWidth <= 1010) configStore.toggleSidebar()

  document.body.scrollTop = 0 // Para Safari
  document.documentElement.scrollTop = 0 // Para Chrome, Firefox, IE y Opera
  router.push({ name: 'productsByCategory', params: { id } })
}

function toggleCategories(index) {
  if (categoriesToShow.value[index]?.sons.length === 0) return
  categoriesToShow.value[index].active = !categoriesToShow.value[index].active
}

onMounted(() => {
  categoriesToShow.value = generateCategoriesToShow()
})
</script>

<style lang="scss" scoped></style>
