import { useUserStore } from '@/stores/user'
export default (axios) => {
  const isClientFastenal = () => {
    const userStore = useUserStore()
    return userStore.isClientFastenal
  }
  return {
    // CRUD functions
    list(payload) {
      return axios.get('v1/products', { params: payload })
    },
    create(data) {
      return axios.post('v1/products', data)
    },
    detail(id, payload) {
      return axios.get(`v1/products_store/${id}`, { params: payload })
    },
    detailPublic(id) {
      return axios.get(`v1/products/${id}/store_public_detail`)
    },
    update(id, data) {
      return axios.put(`v1/products/${id}`, data)
    },
    destroy(id) {
      return axios.delete(`v1/products/${id}`)
    },

    // NO-CRUD functions
    listLines() {
      return axios.get('v1/products/index_lines')
    },
    listBrands() {
      return axios.get('v1/products/index_brands')
    },
    listSublines(payload) {
      return axios.get('v1/products/index_sublines', { params: payload })
    },
    toggle_sold_out(id) {
      return axios.put(`v1/products/${id}/toggle_sold_out`)
    },
    toggle_product(id) {
      return axios.put(`v1/products/${id}/toggle_product`)
    },
    detailAdmin(id) {
      return axios.get(`v1/products/${id}`)
    },
    listStore(payload) {
      return axios.get('v1/products_store', { params: payload })
    },
    listPublic(payload) {
      return axios.get('v1/products/store_public', { params: payload })
    },
    bulk_create(data) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      return axios.post('v1/products/bulk_import', data, { headers })
    },
    updateStock(id, data) {
      return axios.put(`v1/products/${id}/stock`, data)
    }
  }
}
