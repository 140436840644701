export default {
  beforeMount(el) {
    el.addEventListener('blur', () => {
      const value = parseFloat(el.value)

      // Solo corregir si el valor es inválido o menor a 1
      if (isNaN(value) || value < 1) {
        el.value = 1
        // Forzamos que Vue detecte el cambio
        setTimeout(() => {
          el.dispatchEvent(new Event('input'))
        }, 0)
      }
    })
  },
  unmounted(el) {
    el.removeEventListener('blur', null)
  }
}
