import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

const firebaseConfig = {
  apiKey: 'AIzaSyBoxteUz3-voilxeYvidXyiha-sX6hQDFY',
  authDomain: 'dinamicastore-ce0a5.firebaseapp.com',
  projectId: 'dinamicastore-ce0a5',
  storageBucket: 'dinamicastore-ce0a5.appspot.com',
  messagingSenderId: '214697889066',
  appId: '1:214697889066:web:6f35dd2f9741e94dd66253',
  measurementId: 'G-XJHK7JLCR6'
}

const app = initializeApp(firebaseConfig)

let analytics = null
let performance = null

const environment = import.meta.env.VITE_ENVIRONMENT

if (environment === 'production') {
  // Analítica sólo si es compatible
  isAnalyticsSupported().then((supported) => {
    if (supported) {
      analytics = getAnalytics(app)
    }
  })

  performance = getPerformance(app)
}

export { analytics, performance }
