import Swal from 'sweetalert2'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { instance, factories } from '@/api'
import { publicRoutes } from '@/constants/auth'

// Rutas afectadas por el mantenimiento (solo clientes)
const maintenanceRoutes = []

// Refresh token endpoint
const refreshAccessToken = (payload) => {
  const api = factories(instance, ['auth'])
  return api.auth.refreshToken(payload)
}

// Logout and refresh stores
const logOut = () => {
  const authStore = useAuthStore()
  authStore.logout()
}

// Auth guard for route validation
const authGuard = (to, from, next) => {
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const { isLoggedIn } = authStore
  const { getHomeRouteByUserType, hasPermission, getIsAgreementActive, isClient } = userStore
  const toName = to.name.toLowerCase()
  const toIsPublicRoute = publicRoutes.includes(toName)

  const isAgreementRoute = toName.includes('agreement') && !toName.includes('admin')
  const publicRoutesRequiredValidations = [
    'product_detail_public',
    'product_detail_only_list_pack_public',
    'category_public'
  ]
  const routePublicRequiredValidation = publicRoutesRequiredValidations.includes(toName)

  const userHasPermission = toIsPublicRoute ? true : hasPermission(toName)

  // 🔥 Nueva validación: Si la ruta está en mantenimiento
  if (isLoggedIn && isClient && maintenanceRoutes.includes(toName)) {
    Swal.fire({
      title: 'Mantenimiento en progreso',
      text: 'Estamos realizando mejoras en la plataforma. Por favor, intenta de nuevo en más tarde.',
      icon: 'info',
      confirmButtonText: 'Entendido'
    }).then(() => {
      logOut()
      return // Redirigir al login después de mostrar el mensaje
    })
    return // Evita continuar con la ejecución del guard
  }

  if (isLoggedIn && toName === 'login') {
    return next({ name: getHomeRouteByUserType })
  }

  if (isLoggedIn && !toIsPublicRoute) {
    if (userHasPermission) {
      if (isAgreementRoute && !getIsAgreementActive) {
        return next({ name: getHomeRouteByUserType })
      }
      return next()
    }
  }

  if (isLoggedIn && routePublicRequiredValidation) {
    const redirectRoutes = {
      product_detail_public: 'productDetail',
      product_detail_only_list_pack_public: 'productDetail',
      category_public: 'productsByCategory'
    }

    if (redirectRoutes[toName] && to.params.id) {
      return next({ name: redirectRoutes[toName], params: { id: to.params.id } })
    }
  }

  if (toIsPublicRoute) {
    return next()
  }

  return next({ name: !isLoggedIn ? 'login' : getHomeRouteByUserType })
}

export { refreshAccessToken, logOut, authGuard }
