const permissionsByUserType = {
  superadmin: [
    'home',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'shoppingcart',
    'about',
    'branches',
    'contact',
    'orderdetail',
    'backorder',
    'myaccount',
    'admin.users',
    'admin.clients',
    'admin.orders_list',
    'admin.agreement_orders_list',
    'admin.agreement_event_list',
    'admin.agreement_credit_notes_list',
    'admin.agreement_reports',
    'admin.campaign_reports',
    'admin.campaign_reports_no_purchase',
    'admin.quoations_list',
    'admin.order_detail',
    'admin.orders',
    'admin.products_list',
    'admin.agreement_products_list',
    'admin.categories_list',
    'admin.configuration',
    'admin.generate_pdf',
    'admin.product_pdf',
    'admin.client_account',
    'admin.reports',
    'admin.client_active',
    'admin.credit_notes_list',
    'productsbybrand'
  ],
  admin: [
    'home',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'shoppingcart',
    'about',
    'branches',
    'contact',
    'orderdetail',
    'backorder',
    'myaccount',
    'admin.users',
    'admin.clients',
    'admin.orders_list',
    'admin.agreement_orders_list',
    'admin.agreement_event_list',
    'admin.agreement_credit_notes_list',
    'admin.agreement_reports',
    'admin.campaign_reports',
    'admin.campaign_reports_no_purchase',
    'admin.quoations_list',
    'admin.order_detail',
    'admin.orders',
    'admin.products_list',
    'admin.agreement_products_list',
    'admin.categories_list',
    'admin.configuration',
    'admin.client_account',
    'admin.reports',
    'admin.client_active',
    'admin.credit_notes_list',
    'productsbybrand'
  ],
  client: [
    'home',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'shoppingcart',
    'about',
    'branches',
    'contact',
    'orderdetail',
    'backorder',
    'myaccount',
    'agreement',
    'agreement.shopping_cart',
    'agreement.search_products',
    'productsbybrand'
  ],
  prospect: [
    'home',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'about',
    'branches',
    'contact',
    'productsbybrand'
  ],
  client_credit: [
    'home',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'shoppingcart',
    'about',
    'branches',
    'contact',
    'orderdetail',
    'backorder',
    'myaccount',
    'productsbybrand'
  ],
  fastenal: [
    'home',
    'privacy_policy',
    'terms_and_conditions',
    'shoppingcart',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'orderdetail',
    'about',
    'branches',
    'myaccount',
    'contact',
    'productsbybrand'
  ],
  seller: [
    'home',
    'myaccount',
    'searchproducts',
    'productdetail',
    'productsbycategory',
    'searchproducts',
    'shoppingcart',
    'admin.orders_list',
    'admin.client_account',
    'admin.order_detail',
    'admin.clients',
    'admin.client_active',
    'productsbybrand'
  ],
  manager: [
    'home',
    'myaccount',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'shoppingcart',
    'admin.orders_list',
    'admin.agreement_orders_list',
    'admin.agreement_reports',
    'admin.quoations_list',
    'admin.clients',
    'admin.order_detail',
    'admin.client_active',
    'admin.reports',
    'admin.client_account',
    'productsbybrand'
  ],
  operations: [
    'home',
    'myaccount',
    'privacy_policy',
    'terms_and_conditions',
    'productsbycategory',
    'productdetail',
    'searchproducts',
    'admin.orders_list',
    'admin.clients',
    'admin.order_detail',
    'admin.reports',
    'admin.client_account',
    'productsbybrand'
  ],
  customer_service: ['admin.orders_list', 'admin.order_detail', 'admin.clients'],
  default: []
}

export default permissionsByUserType
