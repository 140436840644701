import { logEventSafe } from '@/helpers/analyticsHelper'

export function useAnalytics() {
  const trackClick = (eventName, params = {}, options = {}) => {
    logEventSafe(eventName, params, options)
  }

  const trackSelectItem = (itemName, itemId, listName = '') => {
    logEventSafe('select_item', {
      item_list_name: listName,
      items: [
        {
          item_id: itemId,
          item_name: itemName
        }
      ]
    })
  }

  const trackCategoryClick = (categoryName, categoryId) => {
    trackSelectItem(categoryName, categoryId, 'categorías')
  }

  const trackBannerClick = (banner) => {
    logEventSafe('select_promotion', {
      promotion_id: banner.id,
      promotion_name: banner.title,
      creative_name: banner.image || 'default_image',
      creative_slot: banner.slot || 'home_banner',
      location_id: banner.location || 'homepage'
    })
  }

  const trackBrandFilterClick = (brand) => {
    logEventSafe('select_item', {
      item_list_name: 'Filtro por marca',
      items: [
        {
          item_id: `brand_${brand.id}`,
          item_name: brand.name
        }
      ]
    })
  }

  return {
    trackClick,
    trackSelectItem,
    trackCategoryClick,
    trackBannerClick,
    trackBrandFilterClick
  }
}
