<template>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center flex-column loading z-5">
    <Vue3Lottie ref="vue3LottieRef" :animationData="Loading" width="20%" :speed="3" />
    <div class="modal-backdrop"></div>
  </div>
</template>
<script setup>
import { onUnmounted } from 'vue'
import Loading from '@/assets/lotties/Loading.json'

onUnmounted(() => {
  document.body.style.overflow = 'auto'
})
</script>
